import { ThemeSelector } from '~/components/ui';
import BackgroundLayout from '../../Layout/BackgroundLayout';
import Accordion from '../../Common/Accordion';
import { faqData } from './faqData';
import { ArrowLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export default function HelpAndFAQ() {
  const navigate = useNavigate();

  return (
    <BackgroundLayout>
      <div className="absolute left-0 top-4 z-10 w-full max-w-7xl md:left-4">
        <button
          onClick={() => navigate(-1)}
          className="m-4 flex items-center gap-3 px-3 py-2 text-[18px] text-white"
        >
          <ArrowLeft className="h-5 w-5" />
          Back
        </button>
      </div>
      <div className="relative z-20 w-full max-w-[834px] px-8">
        <div className="mb-4">
          <h2 className="text-text-primary mb-10 text-[30px]">
            Frequently Asked Questions
          </h2>
          <h1 className="gradient-text text-[20px]">
            Unlocking the Power of AI in Marketing
          </h1>
        </div>
        <div className="text-text-primary mb-10">
          Welcome to ayzenberg.ai (beta) - your gateway to the future of
          marketing intelligence. Whether you&apos;re a seasoned marketing
          executive or a curious innovator, this FAQ will guide you through the
          capabilities, features, and potential of ayzenberg.ai. Let&apos;s dive
          into the most common questions about ayzenberg.ai and uncover how it
          can elevate your marketing game.
        </div>
        <div className="space-y-[26px]">
          {faqData.map((faq, index) => (
            <Accordion
              key={index}
              title={faq.title}
              paragraphs={faq.paragraphs}
            />
          ))}
        </div>
        <div className="text-text-primary mt-10 p-[10px]">
          <h2 className="gradient-text mb-2 text-[20px]">
            Need More Information?
          </h2>
          <p>
            For more information on our services or to discuss a new project
            idea, please visit our website{' '}
            <a
              href="https://www.ayzenberg.com/contact/"
              className="link-gradient-text"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ayzenberg: Contact Us
            </a>
            . You can also explore our case studies and services.
          </p>
          <p className="mt-4">
            If you have any additional questions or need assistance with
            ayzenberg.ai, don&apos;t hesitate to contact your account manager or
            drop us a line at{' '}
            <a
              href="mailto:ai@ayzenberg.com"
              className="link-gradient-text"
              target="_blank"
              rel="noopener noreferrer"
            >
              ai@ayzenberg.com
            </a>
            . We&apos;re here to help!
          </p>
        </div>
      </div>
      <div className="absolute bottom-0 left-0 z-20 md:m-4">
        <ThemeSelector />
      </div>
    </BackgroundLayout>
  );
}
