import React, { memo, useEffect, useRef, useState } from 'react';
import rehypeKatex from 'rehype-katex';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import copy from 'copy-to-clipboard';
import { handleDoubleClick, langSubset } from '~/utils';
import Clipboard from '~/components/svg/Clipboard';
import CheckMark from '~/components/svg/CheckMark';
import useLocalize from '~/hooks/useLocalize';

type TCodeProps = {
  inline: boolean;
  className?: string;
  children: React.ReactNode;
};

export const code: React.ElementType = memo(
  ({ inline, className, children }: TCodeProps) => {
    const match = className ? /language-(\w+)/.exec(className) : null;
    let lang = match && match[1] ? match[1] : '';

    // Map common language identifiers
    const languageMap: { [key: string]: string } = {
      py: 'python',
      js: 'javascript',
      ts: 'typescript',
      jsx: 'javascript',
      tsx: 'typescript',
      rb: 'ruby',
      rs: 'rust',
      sh: 'bash',
      shell: 'bash',
      yml: 'yaml',
      txt: 'plaintext',
      html: 'xml',
    };

    if (lang !== '' && languageMap[lang]) {
      lang = languageMap[lang];
    }

    if (inline) {
      return (
        <code onDoubleClick={handleDoubleClick} className={className}>
          {children}
        </code>
      );
    }

    const effectiveLang = !lang || lang === 'txt' ? 'plaintext' : lang;

    return <code className={`hljs language-${effectiveLang}`}>{children}</code>;
  },
);

type RehypePlugin = [
  typeof rehypeKatex | typeof rehypeHighlight,
  Record<string, unknown>,
];
type ReactMarkdownProps = {
  rehypePlugins: RehypePlugin[];
  components: { [key: string]: React.ElementType };
  children: string;
};

export const CodeMarkdown = memo(
  ({
    content = '',
    isSubmitting,
  }: {
    content: string;
    isSubmitting: boolean;
  }) => {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [userScrolled, setUserScrolled] = useState(false);
    const rehypePlugins: RehypePlugin[] = [
      [rehypeKatex, { output: 'mathml' }],
      [
        rehypeHighlight,
        {
          detect: true,
          ignoreMissing: true,
          subset: langSubset,
          aliases: {
            'c++': 'cpp',
            'c#': 'csharp',
            'f#': 'fsharp',
            'objective-c': 'objectivec',
            py: 'python',
            js: 'javascript',
            ts: 'typescript',
            jsx: 'javascript',
            tsx: 'typescript',
            rb: 'ruby',
            rs: 'rust',
            sh: 'bash',
            shell: 'bash',
            yml: 'yaml',
            txt: 'plaintext',
            python: 'python',
            javascript: 'javascript',
            typescript: 'typescript',
            java: 'java',
            ruby: 'ruby',
            rust: 'rust',
            go: 'go',
            cpp: 'cpp',
            csharp: 'csharp',
            php: 'php',
            swift: 'swift',
            kotlin: 'kotlin',
            scala: 'scala',
            perl: 'perl',
            html: 'xml',
            css: 'css',
            sql: 'sql',
            yaml: 'yaml',
            json: 'json',
            xml: 'xml',
            markdown: 'markdown',
            plaintext: 'plaintext',
          },
        },
      ],
    ];

    useEffect(() => {
      const scrollContainer = scrollRef.current;
      if (!scrollContainer) {return;}

      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
        const isNearBottom = scrollHeight - scrollTop - clientHeight < 50;
        setUserScrolled(!isNearBottom);
      };

      scrollContainer.addEventListener('scroll', handleScroll);
      return () => scrollContainer.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
      const scrollContainer = scrollRef.current;
      if (!scrollContainer || !isSubmitting || userScrolled) {return;}

      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }, [content, isSubmitting, userScrolled]);
    return (
      <div ref={scrollRef} className="max-h-full overflow-y-auto">
        <ReactMarkdown
          /* @ts-ignore */
          rehypePlugins={rehypePlugins}
          components={
            { code } as {
              [key: string]: React.ElementType;
            }
          }
        >
          {content}
        </ReactMarkdown>
      </div>
    );
  },
);

export const CopyCodeButton: React.FC<{ content: string }> = ({ content }) => {
  const localize = useLocalize();
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    copy(content, { format: 'text/plain' });
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 3000);
  };

  return (
    <button
      className="text-text-secondary mr-2"
      onClick={handleCopy}
      aria-label={
        isCopied ? localize('com_ui_copied') : localize('com_ui_copy_code')
      }
    >
      {isCopied ? <CheckMark className="h-[18px] w-[18px]" /> : <Clipboard />}
    </button>
  );
};
