export const getEnvironment = (): 'development' | 'qa' | 'production' => {
  const hostname = window.location.hostname;

  if (hostname.includes('localhost') || hostname.includes('dev.')) {
    return 'development';
  }
  
  if (hostname.includes('qa.')) {
    return 'qa';
  }
  
  return 'production';
};
