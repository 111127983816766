import { EModelEndpoint } from 'librechat-data-provider';

interface ModelSpecDescription {
  mobileDescription: string;
  desktopDescription: string;
}

export const getModelSpecDescriptions = (
  modelLabel?: string | null,
): ModelSpecDescription[] => {
  switch (modelLabel) {
    case 'GPT-4o':
      return [
        {
          mobileDescription:
            'GPT-4: Advanced model for complex tasks with enhanced reasoning and creative capabilities.',
          desktopDescription:
            'GPT-4 is OpenAI\'s most advanced model, offering superior performance in complex reasoning, creative tasks, and detailed analysis. It excels at understanding context and generating nuanced responses across diverse applications.',
        },
      ];
    case 'Claude 3.5 Sonnet':
      return [
        {
          mobileDescription:
            'Claude 3.5 Sonnet: Excels in natural conversations, suited for tasks requiring nuanced understanding and ethics.',
          desktopDescription:
            'Claude 3.5 Sonnet excels in creating natural, human-like conversations, making it ideal for tasks requiring nuanced understanding and direction-following, especially in creative or ethical contexts.',
        },
      ];
    case 'Gemini 1.5 Pro':
      return [
        {
          mobileDescription:
            'Gemini 1.5 Pro: Large context window for complex reasoning, perfect for detailed analysis in science and finance.',
          desktopDescription:
            'Gemini 1.5 Pro features a vast context window, perfect for handling large-scale data and complex reasoning, making it a top choice for detailed analysis in science and finance.',
        },
      ];
    case 'Llama 3.2 90b Vision Instruct Turbo':
      return [
        {
          mobileDescription:
            'Llama 3: High-performing open-source model for language and logic tasks.',
          desktopDescription:
            'Llama 3 is a high-performing, open-source model, excelling in language and logic tasks. Its accessibility and adaptability make it a key tool for developers and researchers.',
        },
      ];
    case 'AI Insight Intern':
      return [
        {
          mobileDescription:
            'AI Insight Intern: Transforms observations into actionable insights for creative and strategic thinking.',
          desktopDescription:
            'The AI Insight Intern prompt is designed to help you unlock deeper, more actionable insights by transforming observations into impactful revelations. While it doesn\'t replace a traditional brief, its output should enhance your ability to think creatively and strategically by reframing common beliefs, highlighting limitations, and exploring new angles.',
        },
      ];
    default:
      return [
        {
          mobileDescription:
            'ayzenberg.ai leverages 20 years of social brand strategies to enhance marketing campaigns with proven expertise.',
          desktopDescription:
            'ayzenberg.ai is trained on 20 years of social brand strategies and award-winning campaigns. With access to a rich knowledge base from AlistDaily, it\'s a powerful tool for modern marketers seeking to enhance campaigns with proven insights and industry-leading knowledge.',
        },
      ];
  }
};

export default getModelSpecDescriptions;
