import { useRecoilState } from 'recoil';
import * as Select from '@ariakit/react/select';
import { Fragment, useState, memo } from 'react';
import { FileText, LogOut, Users } from 'lucide-react';
import {
  useGetUserBalance,
  useGetStartupConfig,
} from 'librechat-data-provider/react-query';
import { LinkIcon, GearIcon, DropdownMenuSeparator } from '~/components';
import FilesView from '~/components/Chat/Input/Files/FilesView';
import { useAuthContext } from '~/hooks/AuthContext';
import { UserIcon } from '~/components/svg';
import { useLocalize } from '~/hooks';
import Settings from './Settings';
import store from '~/store';
import { useNavigate } from 'react-router-dom';

function AccountSettings() {
  const localize = useLocalize();
  const navigate = useNavigate();
  const { user, isAuthenticated, logout } = useAuthContext();
  const { data: startupConfig } = useGetStartupConfig();
  const balanceQuery = useGetUserBalance({
    enabled: !!isAuthenticated && startupConfig?.checkBalance,
  });
  const [showSettings, setShowSettings] = useState(false);
  const [showFiles, setShowFiles] = useRecoilState(store.showFiles);

  // const avatarSrc = useAvatar(user);
  const name = user?.name ?? user?.username ?? localize('com_nav_user');

  return (
    <Select.SelectProvider>
      <Select.Select
        aria-label={localize('com_nav_account_settings')}
        data-testid="nav-user"
        className="mt-text-sm flex h-auto w-full items-center gap-2 rounded-xl p-2 text-sm transition-all duration-200 ease-in-out hover:bg-white dark:hover:bg-[#1C2846]"
      >
        <div className="-ml-0.9 -mt-0.8 h-8 w-8 flex-shrink-0">
          <div className="relative flex">
            <div
              className="magenta-gradient-background relative flex items-center justify-center rounded-full text-white"
              style={{
                width: '32px',
                height: '32px',
              }}
              aria-hidden="true"
            >
              {<UserIcon />}
            </div>
          </div>
        </div>
        <div
          className="text-text-primary mt-2 grow overflow-hidden text-ellipsis whitespace-nowrap text-left"
          style={{ marginTop: '0', marginLeft: '0' }}
        >
          {name}
        </div>
      </Select.Select>
      <Select.SelectPopover
        className="popover-ui w-[235px]"
        style={{
          transformOrigin: 'bottom',
          marginRight: '0px',
          translate: '0px',
        }}
      >
        <div
          className="text-text-primary ml-3 mr-2 py-2 text-sm"
          role="note"
          id="gtm-email"
        >
          {user?.email ?? localize('com_nav_user')}
        </div>
        <DropdownMenuSeparator />
        {startupConfig?.checkBalance === true &&
          balanceQuery.data != null &&
          !isNaN(parseFloat(balanceQuery.data)) && (
          <>
            <div
              className="text-token-text-secondary ml-3 mr-2 py-2 text-sm"
              role="note"
            >
              {`Balance: ${parseFloat(balanceQuery.data).toFixed(2)}`}
            </div>
            <DropdownMenuSeparator />
          </>
        )}
        <Select.SelectItem
          value="my_files"
          onClick={() => setShowFiles(true)}
          className="select-item text-text-primary text-sm"
        >
          <FileText className="icon-md" aria-hidden="true" />
          {localize('com_nav_my_files')}
        </Select.SelectItem>
        {(user?.role === 'USER_MANAGER' || user?.role === 'ADMIN') && (
          <Select.SelectItem
            value="whitelist_management"
            onClick={() => navigate('/whitelist')}
            className="select-item text-text-primary text-sm"
          >
            <Users className="icon-md" aria-hidden="true" />
            Whitelist Management
          </Select.SelectItem>
        )}
        {startupConfig?.helpAndFaqURL !== '/' && (
          <Select.SelectItem
            value="help_faq"
            onClick={() => navigate('/help-faq')}
            className="select-item text-text-primary text-sm"
          >
            <LinkIcon aria-hidden="true" />
            {localize('com_nav_help_faq')}
          </Select.SelectItem>
        )}
        <Select.SelectItem
          value="settings"
          onClick={() => setShowSettings(true)}
          className="select-item text-text-primary text-sm"
        >
          <GearIcon className="icon-md" aria-hidden="true" />
          {localize('com_nav_settings')}
        </Select.SelectItem>
        <DropdownMenuSeparator />
        <Select.SelectItem
          value="logout"
          onClick={() => logout()}
          className="select-item text-text-primary text-sm"
        >
          <LogOut className="icon-md" />
          {localize('com_nav_log_out')}
        </Select.SelectItem>
      </Select.SelectPopover>
      {showFiles && <FilesView open={showFiles} onOpenChange={setShowFiles} />}
      {showSettings && (
        <Settings open={showSettings} onOpenChange={setShowSettings} />
      )}
    </Select.SelectProvider>
  );
}

export default memo(AccountSettings);
