export interface ConvoPrompt {
  text: string;
  emoji: string;
}

export interface ConvoPromptsProps {
  modelLabel?: string | null;
}

export const getConvoPrompts = (modelLabel?: string | null): ConvoPrompt[] => {
  switch (modelLabel) {
    case 'GPT-4o':
      return [
        {
          text: 'Craft a quick script for a smoothie brand\'s social media recipe video',
          emoji: '🍓',
        },
        {
          text: 'Write JavaScript to calculate digital marketing ROI',
          emoji: '💻',
        },
        {
          text: 'Develop a data-driven strategy to launch a product in a competitive market',
          emoji: '📈',
        },
        {
          text: 'Design a mobile app feature to boost user engagement with personalized marketing',
          emoji: '📱',
        },
      ];
    case 'Claude 3.5 Sonnet':
      return [
        {
          text: 'Ideate a digital campaign to enhance tech startup brand awareness',
          emoji: '🖥️',
        },
        {
          text: 'Draft a case study on a successful viral marketing campaign',
          emoji: '📚',
        },
        {
          text: 'Plan logistics for a product launch event to maximize media coverage',
          emoji: '📦',
        },
        {
          text: 'Build an HTML Tic Tac Toe game for marketing data collection',
          emoji: '🎮',
        },
      ];
    case 'Gemini 1.5 Pro':
      return [
        {
          text: 'Turn an offline marketing strategy into a digital campaign',
          emoji: '🌐',
        },
        {
          text: 'Plan a webinar to educate and promote brand expertise',
          emoji: '🎤',
        },
        {
          text: 'Create a plan for a global influencer campaign with automatic translations',
          emoji: '🌐',
        },
        {
          text: 'Propose an influencer collaboration on sustainable fashion trends',
          emoji: '🌿',
        },
      ];
    case 'Llama 3.2 90b Vision Instruct Turbo':
      return [
        {
          text: 'Innovate ways to use influencers in a marketing campaign',
          emoji: '📢',
        },
        {
          text: 'Conduct a SWOT analysis for a new market segment and suggest actions',
          emoji: '🔍',
        },
        {
          text: 'Start a conversation that draws users into your brand story',
          emoji: '👋',
        },
        {
          text: 'Refresh brand perception with a new campaign',
          emoji: '🔄',
        },
      ];
    case 'AI Insight Intern':
      return [
        {
          text: 'Use an eco-friendly water bottle analogy for a sustainability campaign',
          emoji: '🧠',
        },
        {
          text: 'Reframe streaming service limitations as a personalized journey in user strategy',
          emoji: '🔄',
        },
        {
          text: 'Propose how Gen Z\'s attention span can enhance concise marketing content',
          emoji: '🔍',
        },
        {
          text: 'Link luxury fashion to art to emphasize craftsmanship in branding',
          emoji: '🎨',
        },
      ];
    default:
      return [
        { text: 'Calculate the EMV for a Youtube video', emoji: '🎯' },
        {
          text: ' Provide a research report the impact of social media influencers on fashion purchases.',
          emoji: '📊',
        },
        {
          text: 'Generate a holiday cocktail recipe. I like citrus and cinnamon. Generate an image of the drink as well',
          emoji: '🍹',
        },

        {
          text: 'Generate a cinematic image, sized 1024x1024, of a dramatic city skyline',
          emoji: '🌆',
        },
      ];
  }
};

export default getConvoPrompts;
