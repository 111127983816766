import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useRegisterUserMutation } from 'librechat-data-provider/react-query';
import type { TRegisterUser, TError } from 'librechat-data-provider';
import type { TLoginLayoutContext } from '~/common';
import { ErrorMessage } from './ErrorMessage';
import { Spinner } from '~/components/svg';
import { useLocalize } from '~/hooks';
import { trackAuthEvent, trackAuthError } from '~/utils/gtm';
import './css/button.css';

const Registration: React.FC = () => {
  const navigate = useNavigate();
  const localize = useLocalize();
  const { startupConfig, startupConfigError, isFetching } =
    useOutletContext<TLoginLayoutContext>();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<TRegisterUser>({ mode: 'onChange' });

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [magicLinkSent, setMagicLinkSent] = useState(false);

  const email = watch('email');

  const isInternalEmail = (email: string) => {
    return email.toLowerCase().endsWith('@ayzenberg.com');
  };

  const checkWhitelist = async (email: string) => {
    try {
      const response = await fetch('/api/whitelist/check', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Failed to check whitelist status');
      }

      const result = await response.json();
      return result.isAllowed;
    } catch (error) {
      console.error('Whitelist check error:', error);
      throw error;
    }
  };

  const handleRegistration = async (data: TRegisterUser) => {
    setIsSubmitting(true);
    const isInternal = isInternalEmail(data.email);

    // Track registration attempt
    trackAuthEvent('magic_link_register', data.email, 'attempt', {
      is_internal: isInternal,
    });

    try {
      // For external emails, check whitelist first
      if (!isInternal) {
        const isAllowed = await checkWhitelist(data.email);
        if (!isAllowed) {
          const error = localize('com_auth_error_create_external');
          trackAuthError('magic_link_register', data.email, error, 'validation_failed');
          throw new Error(error);
        }
      }

      const response = await fetch('/api/auth/magic-link-register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: data.email,
          name: data.name,
        }),
      });

      const result = await response.json();

      if (!response.ok) {
        if (isInternal) {
          const error = 'You\'re almost there! Please return to the login page to continue our secure login process with Okta using your Ayzenberg email.';
          trackAuthError('magic_link_register', data.email, error, 'internal_email_redirect');
          setErrorMessage(error);
          return;
        }
        if (result.error?.includes('already exists for this organization')) {
          const error = 'You\'re almost there! Please return to the login page to continue our secure login process';
          trackAuthError('magic_link_register', data.email, error, 'user_exists');
          throw new Error(error);
        }
        throw new Error(result.error || 'Failed to send magic link');
      }

      setMagicLinkSent(true);

      // Track successful registration
      trackAuthEvent('magic_link_register', data.email, 'success', {
        is_internal: isInternal,
      });
    } catch (error: unknown) {
      console.error('Registration error:', error);
      const errorMessage = error instanceof Error ? error.message : 'Registration failed';
      setErrorMessage(errorMessage);

      // If not already tracked as a specific error, track as unknown error
      if (!errorMessage.includes('Please return to the login page')) {
        trackAuthError('magic_link_register', data.email, errorMessage, 'unknown');
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderInput = (
    id: string,
    label: string,
    type: string,
    validation: object,
  ) => (
    <div className="mb-4">
      <div className="relative w-[272px]">
        <div
          className="rounded-lg p-[1px]"
          style={{
            background: 'linear-gradient(91.969deg, #F214A1 0%, #BC0278 100%)',
          }}
        >
          <div className="rounded-lg bg-[#F2F2F2] dark:bg-[#0f172a]">
            <input
              id={id}
              type={type}
              autoComplete={id}
              aria-label={localize(label)}
              {...register(id as 'name' | 'email', validation)}
              aria-invalid={!!errors[id]}
              className="form-input peer block w-full appearance-none rounded-lg px-4 py-3 text-base text-white focus:outline-none focus:ring-0 dark:text-white"
              placeholder=" "
              data-testid={id}
            />
            <label
              htmlFor={id}
              className="absolute start-1 top-2 z-10 origin-[0] -translate-y-4 scale-75 transform px-3 text-sm text-[#0f172a] opacity-80 duration-100 peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:scale-100 peer-focus:top-2 peer-focus:-translate-y-4 peer-focus:scale-75 peer-focus:px-3 rtl:peer-focus:left-auto rtl:peer-focus:translate-x-1/4 dark:text-white dark:peer-focus:text-white"
            >
              {localize(label)}
            </label>
          </div>
        </div>
      </div>
      {errors[id] && (
        <span
          role="alert"
          className="magenta-to-yellow-title-gradient mt-1 text-sm"
        >
          {String(errors[id]?.message) ?? ''}
        </span>
      )}
    </div>
  );

  if (magicLinkSent) {
    return (
      <div className="mx-auto max-w-[272px] text-center">
        <div className="mb-4 text-lg font-semibold text-[#0f172a] dark:text-white">
          Check your email
        </div>
        <p className="text-sm text-gray-600 dark:text-gray-300">
          We&apos;ve sent a magic link to <strong>{email}</strong>. Click the
          link to sign in to your account.
        </p>
        <button
          onClick={() => setMagicLinkSent(false)}
          className="mt-4 text-sm text-[#BC0278] hover:text-[#F214A1] dark:text-[#F214A1] dark:hover:text-[#BC0278]"
        >
          Use a different email
        </button>
      </div>
    );
  }

  return (
    <>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {!startupConfigError && !isFetching && (
        <>
          <div className="flex justify-center">
            <form
              className="mt-8"
              aria-label="Registration form"
              method="POST"
              onSubmit={handleSubmit(handleRegistration)}
            >
              {renderInput('name', 'com_auth_full_name', 'text', {
                required: localize('com_auth_name_required'),
                minLength: {
                  value: 3,
                  message: localize('com_auth_name_min_length'),
                },
                maxLength: {
                  value: 80,
                  message: localize('com_auth_name_max_length'),
                },
              })}
              {renderInput('email', 'com_auth_email', 'email', {
                required: localize('com_auth_email_required'),
                minLength: {
                  value: 1,
                  message: localize('com_auth_email_min_length'),
                },
                maxLength: {
                  value: 120,
                  message: localize('com_auth_email_max_length'),
                },
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: localize('com_auth_email_pattern'),
                },
              })}
              <div className="mt-6">
                <button
                  disabled={Object.keys(errors).length > 0}
                  type="submit"
                  aria-label="Submit registration"
                  className="auth-button w-full transform rounded-lg px-4 py-3 tracking-wide text-white transition-colors duration-200 hover:text-[#0F172A] focus:outline-none disabled:cursor-not-allowed disabled:hover:bg-green-500"
                >
                  {isSubmitting ? <Spinner /> : localize('com_auth_continue')}
                </button>
              </div>
            </form>
          </div>

          <p className="my-4 text-center text-sm font-normal text-[#0f172a] dark:text-white">
            {localize('com_auth_already_have_account')}{' '}
            <a
              href="/login"
              aria-label="Login"
              className="gradient-text-underline"
            >
              {localize('com_auth_login')}
            </a>
          </p>
        </>
      )}
    </>
  );
};

export default Registration;
