import React, { useState, useRef } from 'react';
import IconMinus from '/assets/icons/iconMinus.svg';
import LightIconMinus from '/assets/icons/lightIconMinus.svg';
import '../css/button.css';
import '../css/Infosection.css';

type InfoSectionProps = {
  title: string;
  paragraphs: string[];
  buttonText?: string | null;
};

const InfoSectionComponent: React.FC<InfoSectionProps> = ({
  title,
  paragraphs,
  buttonText,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`info-section-background ${isOpen ? 'open' : ''}`}>
      <div
        className="flex cursor-pointer items-center justify-between p-6"
        onClick={toggleSection}
        onKeyUp={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleSection();
          }
        }}
        role="button"
        tabIndex={0}
      >
        <h3 className="magenta-to-yellow-title-gradient text-xl font-medium leading-7">
          {title}
        </h3>
        <div className="relative">
          {/* light icon */}
          <div>
            <img
              src={LightIconMinus}
              alt={isOpen ? 'Open' : 'Close'}
              className="h-6 w-6 transition-transform duration-200 ease-linear dark:hidden"
              style={{
                transform: isOpen ? 'rotate(0deg)' : 'rotate(90deg)',
              }}
            />
            <img
              src={LightIconMinus}
              alt={isOpen ? 'Close' : 'Open'}
              className="absolute left-0 top-0 h-6 w-6 transition-transform duration-200 ease-linear dark:hidden"
              style={{
                transform: isOpen ? 'rotate(0deg)' : 'rotate(0deg)',
              }}
            />
          </div>

          {/* dark icon */}
          <div>
            <img
              src={IconMinus}
              alt={isOpen ? 'Open' : 'Close'}
              className="hidden h-6 w-6 transition-transform duration-200 ease-linear dark:block"
              style={{
                transform: isOpen ? 'rotate(0deg)' : 'rotate(90deg)',
              }}
            />
            <img
              src={IconMinus}
              alt={isOpen ? 'Close' : 'Open'}
              className="absolute left-0 top-0 hidden h-6 w-6 transition-transform duration-200 ease-linear dark:block"
              style={{
                transform: isOpen ? 'rotate(0deg)' : 'rotate(0deg)',
              }}
            />
          </div>
        </div>
      </div>
      <div
        ref={contentRef}
        className="transition-max-height overflow-hidden opacity-100 duration-500 ease-in-out"
        style={{
          maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : '0px',
        }}
      >
        <div className="p-6">
          {paragraphs.map((paragraph, index) => (
            <p
              key={index}
              className="mb-4 text-base font-normal leading-normal text-[#0F172A] dark:text-white"
            >
              {paragraph}
            </p>
          ))}
          {buttonText ? (
            <a href="mailto:ai@ayzenberg.com">
              <button className="auth-button rounded-md px-4 py-2 text-white transition-colors hover:text-[#0F172A]">
                {buttonText}
              </button>
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const infoSectionData = [
  {
    title: 'What is ayzenberg.ai?',
    paragraphs: [
      'ayzenberg.ai was developed to add a new dimension to the Ayzenberg network\'s extensive collection of data, analytics, interviews, and case studies from properties including AListDaily.com, Ayzenberg.com, and ION.co.',
      'With over 25 years of social trend acceleration strategies embedded in its training, ayzenberg.ai provides you with the knowledge and tactics used in global campaigns from top brands with just a prompt, correlating insights from across our archives—insights that you can trust.',
    ],
  },
  {
    title: 'Ready to Explore?',
    paragraphs: [
      'ayzenberg.ai is currently available exclusively for staff and select clients, and requires an account for access. If you would like to inquire about personal or organizational access, please reach out via the button below.',
    ],
    buttonText: 'Contact Us',
  },
];

export default InfoSectionComponent;
