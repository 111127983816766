import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStytch } from './StytchProvider';
import { useAuthContext } from '~/hooks/AuthContext';
import { trackStytchSession } from '~/utils/gtm';

export const AuthenticateRoute = () => {
  const stytchClient = useStytch();
  const navigate = useNavigate();
  const { setError } = useAuthContext();
  const [lastAttemptError, setLastAttemptError] = useState<string | null>(null);

  useEffect(() => {
    const authenticate = async () => {
      try {
        console.log('[AuthenticateRoute] Starting authentication flow');
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        console.log('[AuthenticateRoute] Token from URL:', !!token);

        if (!token) {
          throw new Error('No token found in URL');
        }

        console.log('[AuthenticateRoute] Authenticating with Stytch...');
        const stytchAuth = await stytchClient.sso.authenticate({
          sso_token: token,
          session_duration_minutes: 60,
        });
        console.log('[AuthenticateRoute] Stytch authentication successful');

        console.log('[AuthenticateRoute] Calling backend authenticate endpoint...');
        const response = await fetch(`/api/auth/authenticate?token=${token}`, {
          credentials: 'include',
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to authenticate with server');
        }

        const authData = await response.json();
        console.log('[AuthenticateRoute] Backend authentication successful');

        // Track successful Stytch session start
        if (stytchAuth.member_session && stytchAuth.member) {
          trackStytchSession({
            session_id: stytchAuth.member_session.member_session_id,
            session_started: stytchAuth.member_session.started_at,
            member_id: stytchAuth.member.member_id,
            email: authData.user.email,
            organization_id: stytchAuth.organization?.organization_id,
            event_type: 'session_start',
          });
        }

        // Dispatch token updated event
        window.dispatchEvent(new CustomEvent('tokenUpdated', {
          detail: {
            token: authData.token,
            user: authData.user,
            isAuthenticated: authData.isAuthenticated,
          },
        }));

        // Wait a moment for the event to be processed
        await new Promise(resolve => setTimeout(resolve, 100));

        // Navigate to the app
        navigate('/c/new', { replace: true });
      } catch (err) {
        console.error('[AuthenticateRoute] Authentication error:', err);
        const errorMessage = err instanceof Error ? err.message : 'Authentication failed';
        setLastAttemptError(errorMessage);
        setError(errorMessage);

        // Track authentication error
        trackStytchSession({
          session_id: '', // Empty for failed auth
          member_id: '', // Empty for failed auth
          email: '', // Empty for failed auth
          event_type: 'session_error',
          error: errorMessage,
        });

        navigate('/login', { replace: true });
      }
    };

    authenticate();
  }, [stytchClient, navigate, setError]);

  return (
    <div>
      <div>Authenticating with SSO...</div>
      {lastAttemptError && <div style={{ color: 'red' }}>{lastAttemptError}</div>}
    </div>
  );
};

export default AuthenticateRoute;
