import { getEnvironment } from './environment';

// Event types
type BaseEvent = {
  timestamp: string;
  environment: string;
};

type ToolEvent = BaseEvent & {
  event: 'tool_usage';
  tool_type: string;
  tool_data?: Record<string, unknown>;
  conversation_id?: string;
  message_id?: string;
  success?: boolean;
  error?: string;
};

type ArtifactEvent = BaseEvent & {
  event: 'artifact_usage';
  artifact_id: string;
  artifact_title: string;
  artifact_type: string;
};

type StytchSessionEvent = BaseEvent & {
  event: 'stytch_session';
  session_id: string;
  member_id: string;
  email: string;
  organization_id?: string;
  session_started?: string;
  event_type: 'session_start' | 'session_end' | 'session_error';
  error?: string;
};

// Auth event types
type AuthEventType =
  | 'magic_link_login'
  | 'magic_link_register'
  | 'sso_login';

type AuthEventStatus = 'attempt' | 'success' | 'error';

type AuthErrorReason =
  | 'internal_email_redirect'
  | 'user_exists'
  | 'validation_failed'
  | 'network_error'
  | 'unknown';

type AuthEvent = BaseEvent & {
  event: `${AuthEventType}_${AuthEventStatus}`;
  user_email: string;
  auth_method: 'magic_link' | 'sso';
  error_message?: string;
  error_reason?: AuthErrorReason;
  is_internal?: boolean;
};

type DataLayerEvent = ToolEvent | ArtifactEvent | StytchSessionEvent | AuthEvent;

// Update global dataLayer type
declare global {
  interface Window {
    dataLayer: DataLayerEvent[];
  }
}

const addEnvironmentData = <T extends Partial<DataLayerEvent>>(data: T): T & BaseEvent => {
  return {
    ...data,
    environment: getEnvironment(),
    timestamp: new Date().toISOString(),
  };
};

// Tracking functions
export const trackToolUsage = ({
  tool_type,
  tool_data = {},
  conversation_id,
  message_id,
  success = true,
  error = '',
}: Omit<ToolEvent, keyof BaseEvent | 'event'>): void => {
  if (typeof window === 'undefined') {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  
  const eventData = addEnvironmentData({
    event: 'tool_usage' as const,
    tool_type,
    ...tool_data,
    conversation_id,
    message_id,
    success,
    error,
  });
  
  window.dataLayer.push(eventData as ToolEvent);
};

export const trackArtifactUsage = ({
  artifact_id,
  artifact_title,
  artifact_type,
}: Omit<ArtifactEvent, keyof BaseEvent | 'event'>): void => {
  if (typeof window === 'undefined') {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  
  const eventData = addEnvironmentData({
    event: 'artifact_usage' as const,
    artifact_id,
    artifact_title,
    artifact_type,
  });
  
  window.dataLayer.push(eventData as ArtifactEvent);
};

export const trackStytchSession = ({
  session_id,
  member_id,
  email,
  organization_id,
  session_started,
  event_type,
  error = '',
}: Omit<StytchSessionEvent, keyof BaseEvent | 'event'>): void => {
  if (typeof window === 'undefined') {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  
  const eventData = addEnvironmentData({
    event: 'stytch_session' as const,
    session_id,
    member_id,
    email,
    organization_id,
    session_started,
    event_type,
    error,
  });
  
  window.dataLayer.push(eventData as StytchSessionEvent);
};

// Auth tracking functions
export const trackAuthEvent = (
  eventType: AuthEventType,
  email: string,
  status: AuthEventStatus = 'attempt',
  additionalData: Partial<{
    is_internal: boolean;
    error_message: string;
    error_reason: AuthErrorReason;
  }> = {},
): void => {
  if (typeof window === 'undefined') {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  
  const eventData = addEnvironmentData({
    event: `${eventType}_${status}` as const,
    user_email: email,
    auth_method: eventType.includes('magic_link') ? 'magic_link' : 'sso',
    ...additionalData,
  });
  
  window.dataLayer.push(eventData as AuthEvent);
};

export const trackAuthError = (
  eventType: AuthEventType,
  email: string,
  errorMessage: string,
  reason: AuthErrorReason = 'unknown',
): void => {
  trackAuthEvent(eventType, email, 'error', {
    error_message: errorMessage,
    error_reason: reason,
  });
};
