import { ArrowLeft } from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';
import BackgroundLayout from '~/components/Layout/BackgroundLayout';
import { ThemeSelector } from '~/components/ui';
import { Banner } from '~/components/Banners';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Banner />
      <BackgroundLayout>
        <div className="absolute left-0 top-4 z-10 w-full max-w-7xl md:left-4">
          <button
            onClick={() => navigate(-1)}
            className="text-text-primary m-4 flex items-center gap-3 px-3 py-2 text-[18px]"
          >
            <ArrowLeft className="h-5 w-5" />
            Back
          </button>
        </div>

        <main className="relative z-20 mt-16 w-full max-w-[834px] px-8">
          <h1 className="text-text-primary mb-6 text-center text-[30px] font-light">
            Oops! Page Not Found
          </h1>
          <p className="text-text-primary mb-8 text-center text-[20px]">
            You&apos;ve stumbled into uncharted territory! But even seasoned
            explorers hit a snag now and then.
          </p>

          <div className="text-text-primary space-y-6">
            <div>
              <h2 className="mb-4 text-[24px] font-light">What Happened?</h2>
              <p>
                The page you&apos;re seeking might be on a break. It could be a
                typo or a broken link, but don&apos;t worry, we can guide you
                back.
              </p>
            </div>

            <div>
              <h2 className="mb-4 text-[24px] font-light">What to Do:</h2>
              <ul className="list-inside list-disc space-y-2">
                <li>
                  <strong>Check the URL:</strong> Ensure everything&apos;s
                  spelled correctly.
                </li>
                <li>
                  <strong>Return to Safety:</strong> Head back to our{' '}
                  <Link to="/" className="link-gradient-text">
                    Homepage
                  </Link>
                  .
                </li>
                <li>
                  <strong>Explore:</strong> Check out{' '}
                  <Link to="/alist" className="link-gradient-text">
                    AList
                  </Link>{' '}
                  or{' '}
                  <Link to="/services" className="link-gradient-text">
                    Our Services
                  </Link>
                  .
                </li>
                <li>
                  <strong>Contact Us:</strong> Reach us at{' '}
                  <a
                    href="mailto:ai@ayzenberg.com"
                    className="link-gradient-text"
                  >
                    ai@ayzenberg.com
                  </a>{' '}
                  if you need help!
                </li>
              </ul>
            </div>

            <div>
              <h2 className="mb-4 text-[24px] font-light">Keep Exploring</h2>
              <p>
                A misstep is just a new opportunity. Let&apos;s continue the
                adventure with ayzenberg.ai!
              </p>
            </div>

            <div className="mt-10 text-center">
              <Link
                to="/"
                className="link-button inline-flex h-10 items-center justify-center rounded-lg px-6 py-2 text-sm text-white hover:text-black"
              >
                Return to Homepage
              </Link>
            </div>
          </div>
        </main>

        <div className="absolute bottom-0 left-0 z-20 md:m-4">
          <ThemeSelector />
        </div>
      </BackgroundLayout>
    </div>
  );
};

export default NotFound;
