import { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
  getConfigDefaults,
  PermissionTypes,
  Permissions,
} from 'librechat-data-provider';
import { useGetStartupConfig } from 'librechat-data-provider/react-query';
import type { ContextType } from '~/common';
import {
  EndpointsMenu,
  ModelSpecsMenu,
  PresetsMenu,
  HeaderNewChat,
} from './Menus';
// import ExportAndShareMenu from './ExportAndShareMenu';
import { useHasAccess } from '~/hooks';
import HeaderOptions from './Input/HeaderOptions';
import BookmarkMenu from './Menus/BookmarkMenu';
import AddMultiConvo from './AddMultiConvo';
import SentryWidget from './Menus/SentryWidget';

const defaultInterface = getConfigDefaults().interface;

export default function Header() {
  const { data: startupConfig } = useGetStartupConfig();
  const { navVisible } = useOutletContext<ContextType>();
  const modelSpecs = useMemo(
    () => startupConfig?.modelSpecs?.list ?? [],
    [startupConfig],
  );
  const interfaceConfig = useMemo(
    () => startupConfig?.interface ?? defaultInterface,
    [startupConfig],
  );

  const hasAccessToBookmarks = useHasAccess({
    permissionType: PermissionTypes.BOOKMARKS,
    permission: Permissions.USE,
  });

  const hasAccessToMultiConvo = useHasAccess({
    permissionType: PermissionTypes.MULTI_CONVO,
    permission: Permissions.USE,
  });

  return (
    <div className="sticky top-0 z-10 flex h-14 w-full items-center justify-between bg-white font-semibold md:p-2 dark:bg-[#0f172a] dark:text-white">
      <div className="hide-scrollbar flex w-full items-center justify-between gap-2 overflow-x-auto pl-2 pr-6">
        <div className="flex items-center gap-2">
          {!navVisible && <HeaderNewChat />}
          {interfaceConfig.endpointsMenu === true && <EndpointsMenu />}
          {modelSpecs.length > 0 && <ModelSpecsMenu modelSpecs={modelSpecs} />}
          {<HeaderOptions interfaceConfig={interfaceConfig} />}
          {interfaceConfig.presets === true && <PresetsMenu />}
          {hasAccessToBookmarks === true && <BookmarkMenu />}
          {hasAccessToMultiConvo === true && <AddMultiConvo />}
          {/* <ExportAndShareMenu
            isSharedButtonEnabled={startupConfig?.sharedLinksEnabled ?? false}
          /> */}
        </div>
        <div className="flex items-center">
          <SentryWidget />
        </div>
      </div>
    </div>
  );
}
